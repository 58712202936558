import { nodeListToArray } from "../../utils/HTMLUtils";

declare const Sitecore: any;

export interface IRenderingElement {
    opening: Array<HTMLElement>;
}

export type IAfterFrameLoadedCallback = (callbackData: IRenderingElement) => void;
export type IAfterPersonalizationChangedCallback = (element: HTMLElement) => void;
export type IBeforeUnloadingChromeCallback = (element: HTMLElement) => void;

export class SitecoreExperienceEditor implements ISitecoreExperienceEditor {
    public static isInBackgroundRenderingParameterName = "sc_phk";

    private isAfterFrameLoadedOverriden = false;
    private isAfterPersonalizationChangedOverriden = false;
    private isBeforeUnloadingChromeOverriden = false;

    private onAfterFrameLoadedCallbacks: IAfterFrameLoadedCallback[] = [];
    private onAfterPersonalizationChanged: IAfterPersonalizationChangedCallback[] = [];
    private onBeforeUnloadingChrome: IBeforeUnloadingChromeCallback[] = [];

    public registerOnLoadRenderingFromUrl(onAfterFrameLoadedCallback: (callbackData: IRenderingElement) => void): void {
        this.tryOverrideSitecoreLoadRenderingFromUrl();
        this.onAfterFrameLoadedCallbacks.push(onAfterFrameLoadedCallback);
    }

    public registerBeforeUnloadingChrome(onBeforeUnloadingChromeCallback: IBeforeUnloadingChromeCallback): void {
        this.tryOverrideOnUnloadingChrome();
        this.onBeforeUnloadingChrome.push(onBeforeUnloadingChromeCallback);
    }

    public registerOnAfterPersonalizationChanged(onAfterPersonalizationChanged: IAfterPersonalizationChangedCallback): void {
        this.tryOverrideSitecoreChromeTypesRenderingChangeCondition();
        this.onAfterPersonalizationChanged.push(onAfterPersonalizationChanged);
    }

    public isInBackgroundRendering(): boolean {
        return window.location.search.indexOf(SitecoreExperienceEditor.isInBackgroundRenderingParameterName) !== -1;
    }

    public getComponentFromLoadRenderingFromUrlCallbackData(callbackData: IRenderingElement): HTMLElement {
        var chromeRendering = document.getElementById(callbackData.opening[0].id);
        return chromeRendering.nextElementSibling as HTMLElement;
    }

    private tryOverrideSitecoreLoadRenderingFromUrl(): void {
        if (!this.isAfterFrameLoadedOverriden && typeof (Sitecore) !== "undefined") {
            this.overrideSitecoreLoadRenderingFromUrl();
        }
    }

    private overrideSitecoreLoadRenderingFromUrl(): void {
        const originalLoad = Sitecore.PageModes.ChromeTypes.Placeholder.prototype.insertRendering;
        const onAfterFrameLoadedCallback = (callbackData: IRenderingElement) => {
            this.onAfterFrameLoadedCallbacks.forEach(callback => callback(callbackData));
        };
        Sitecore.PageModes.ChromeTypes.Placeholder.prototype.insertRendering = function (frameLoadedCallback: IRenderingElement): void {
            originalLoad.call(this, frameLoadedCallback);
            onAfterFrameLoadedCallback(frameLoadedCallback);
        };
        this.isAfterFrameLoadedOverriden = true;
    }

    private tryOverrideOnUnloadingChrome(): void {
        if (!this.isBeforeUnloadingChromeOverriden && typeof (Sitecore) !== "undefined") {
            this.overrideSitecoreChromeChangeCondition();
            this.isBeforeUnloadingChromeOverriden = true;
        }
    }

    private overrideSitecoreChromeChangeCondition(): void {
        const originalChangeCondition: Function = Sitecore.PageModes.ChromeTypes.Rendering.prototype.changeCondition;
        const onBeforeChangeCondition = (changedElement: HTMLElement) => {
            this.onBeforeUnloadingChrome.forEach(callback => callback(changedElement));
        };
        Sitecore.PageModes.ChromeTypes.Rendering.prototype.changeCondition = function (id, sender, preserveCacheUpdating): void {
            onBeforeChangeCondition(this.chrome.element[0]);
            originalChangeCondition.apply(this, arguments);
        };
    }

    private tryOverrideSitecoreChromeTypesRenderingChangeCondition(): void {
        if (!this.isAfterPersonalizationChangedOverriden && typeof (Sitecore) !== "undefined") {
            this.overrideSitecoreChromeTypesRenderingChangeCondition();
        }
    }

    private overrideSitecoreChromeTypesRenderingChangeCondition(): void {
        const originalStartActivation: Function = Sitecore.PageModes.ChromeTypes.Rendering.prototype._startActivation;
        const originalEndActivation: Function = Sitecore.PageModes.ChromeTypes.Rendering.prototype._endActivation;
        let elementToProcessWhenFinished: HTMLElement;
        const onAfterPersonalizationChanged = (changedElement: HTMLElement) => {
            this.onAfterPersonalizationChanged.forEach(callback => callback(changedElement));
        };
        const getValidMarker = (markersAndElements: HTMLElement[]): HTMLElement => {
            return this.isDifferentFirstLoadMarker(markersAndElements) ? markersAndElements[2] : markersAndElements[1];
        };
        Sitecore.PageModes.ChromeTypes.Rendering.prototype._startActivation = function (markersAndElements: HTMLElement[], fieldId?: string): void {
            elementToProcessWhenFinished = getValidMarker(markersAndElements);
            originalStartActivation.apply(this, arguments);
        };
        Sitecore.PageModes.ChromeTypes.Rendering.prototype._endActivation = function (reason: string): void {
            originalEndActivation.apply(this, arguments);
            if (elementToProcessWhenFinished) {
                onAfterPersonalizationChanged(elementToProcessWhenFinished);
            }
        };
        this.isAfterPersonalizationChangedOverriden = true;
    }

    // In Sitecore 8.0, for some reason, there are two additionnal text nodes *only on the first load*.
    // The cached version does not have those.
    private isDifferentFirstLoadMarker(markersAndElements: HTMLElement[]): boolean {
        return markersAndElements[1].nodeType === Node.TEXT_NODE;
    };

    public registerShowDebugInformationCallback(): void {
        if (!window["scCoveoShowDebugInformation"]) {
            window["scCoveoShowDebugInformation"] = (debugModeEnabled: boolean) => {
                const debugInfoElements = nodeListToArray(document.getElementsByClassName("coveo-debug-information"));
                if (debugModeEnabled) {
                    debugInfoElements.forEach(element => element.classList.remove("coveo-debug-hidden"));
                } else {
                    debugInfoElements.forEach(element => element.classList.add("coveo-debug-hidden"));
                }
                // Chromes are not updated correctly until an event refreshes them. This forces the update.
                Sitecore.PageModes.ChromeManager.resetChromes();
            };
        }
    }
}

export interface ISitecoreExperienceEditor {
    registerOnLoadRenderingFromUrl(onAfterFrameLoadedCallback: (callbackData: IRenderingElement) => void): void;
    registerBeforeUnloadingChrome(onBeforeUnloadingChromeCallback: IBeforeUnloadingChromeCallback): void;
    registerOnAfterPersonalizationChanged(onAfterPersonalizationChanged: IAfterPersonalizationChangedCallback): void;
    isInBackgroundRendering(): boolean;
    getComponentFromLoadRenderingFromUrlCallbackData(callbackData: IRenderingElement): HTMLElement;
    registerShowDebugInformationCallback(): void;
}

const staticInstance = new SitecoreExperienceEditor();

export function getExperienceEditorInstance(): ISitecoreExperienceEditor {
    return staticInstance;
}
