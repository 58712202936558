import { IPrebindHelper, IPrebindHelperInitializationArgs } from "../PrebindHelper";
import { IPrebindHandler } from "../PrebindResolver";

export class CurrentMaximumAge implements IPrebindHelper {
    public name: string = "currentMaximumAge";

    public getPrebind(args: IPrebindHelperInitializationArgs): IPrebindHandler {
        return (value: string, element: HTMLElement) => {
            return value || args.context.scMaximumAge.toString();
        };
    }
}
