import {
    IComponentDefinition,
    LazyInitialization
} from "coveo-search-ui";

export function lazyCoveoForSitecoreSyntax(): void {
    LazyInitialization.registerLazyComponent("ForSitecoreSyntax", () => {
        return new Promise((resolve, reject) => {
            require.ensure(["./CoveoForSitecoreSyntax"], () => {
                const loaded = require<IComponentDefinition>("./CoveoForSitecoreSyntax.ts")["CoveoForSitecoreSyntax"];
                resolve(loaded);
            }, LazyInitialization.buildErrorCallback("CoveoForSitecoreSyntax", resolve), "CoveoForSitecoreSyntax");
        });
    });
}
