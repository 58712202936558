import {
    PublicPathUtils,
    $$,
    Logger
} from "coveo-search-ui";
import { nodeListToArray } from "./HTMLUtils";

/**
 * Copied from: https://github.com/coveo/search-ui/blob/master/src/utils/PublicPathUtils.ts
 * The only modified part is `getCoveoScript()` which references the .coveo-for-sitecore-script instead of coveo-script.
 */
export class SitecorePublicPathUtils extends PublicPathUtils {
    // Overrides the coveo-search-ui implementation to check for our script.
    public static getCoveoScript() {
        const scriptElements = nodeListToArray(document.querySelectorAll('.coveo-for-sitecore-script')).filter(el => this.isScript(el));
        if (scriptElements.length !== 0) {
            return scriptElements[0];
        } else {
            // Message is strongly inspired by the one in the coveo-search-ui.
            new Logger(this)
                .warn(`You should add the class coveo-for-sitecore-script on the script tag that includes the Coveo for Sitecore script. Not doing so may cause the framework to not be able to auto-detect the path to load the lazy chunks in certain environments.
                More details here https://docs.coveo.com/en/295/javascript-search-framework/lazy-versus-eager-component-loading#fixing-code-chunks-loading-path-issues`);
            // Returning a value so that the original `getDynamicPublicPath` does not warn a second time.
            return this.getCurrentScript();
        }
    }

    private static isScript(el: Element): el is HTMLScriptElement {
        return el && $$(<HTMLElement>el).is('script');
    }
}