import {
    IComponentDefinition,
    LazyInitialization
} from "coveo-search-ui";

export function lazyCoveoForSitecoreBindWithUserContext(): void {
    LazyInitialization.registerLazyComponent("ForSitecoreBindWithUserContext", () => {
        return new Promise((resolve, reject) => {
            require.ensure(["./CoveoForSitecoreBindWithUserContext"], () => {
                const loaded = require<IComponentDefinition>("./CoveoForSitecoreBindWithUserContext.ts")["CoveoForSitecoreBindWithUserContext"];
                resolve(loaded);
            }, LazyInitialization.buildErrorCallback("CoveoForSitecoreBindWithUserContext", resolve), "CoveoForSitecoreBindWithUserContext");
        });
    });
}
