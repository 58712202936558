import { ICoveoFieldHandler } from "../handlers/CoveoFieldsHandler";
import { ICoveoLabelsHandler } from "../handlers/CoveoLabelsHandler";
import { ContextInitializer } from "./initialization/ContextInitializer";
import { CoveoForSitecoreContext, ICoveoForSitecoreContextOptions } from "../ui/context/CoveoForSitecoreContext";
import { ComponentOptions } from "coveo-search-ui";

export interface IOnContextConfiguredHandler {
    handle(contextElement: HTMLElement): void;
}

export class Context {
    public static fields: ICoveoFieldHandler;
    public static labels: ICoveoLabelsHandler;

    public static element: HTMLElement;

    static configureContext(contextElement: HTMLElement): void {
        if (typeof (contextElement) === "undefined" || contextElement === null) {
            throw Error("You must execute `configureContext` with an HTML element.");
        }

        const options: ICoveoForSitecoreContextOptions = ComponentOptions.initComponentOptions(contextElement, CoveoForSitecoreContext);
        this.initializeWithContextOptions(contextElement, options);
    }

    private static initializeWithContextOptions(contextElement: HTMLElement, contextOptions: ICoveoForSitecoreContextOptions): void {
        const contextInitializer = new ContextInitializer();
        const fieldsHandler = contextInitializer.createFieldsHandler({
            externalFields: contextOptions.scExternalFields,
            sourceSpecificFields: contextOptions.scSourceSpecificFields,
            preferSourceSpecificFields: contextOptions.scPreferSourceSpecificFields,
            prefix: contextOptions.scFieldPrefix,
            suffix: contextOptions.scFieldSuffix
        });
        const labels: ICoveoLabelsHandler = contextInitializer.createLabelsHandler({
            labels: contextOptions.scLabels
        });
        const sourceName = contextOptions.scIndexSourceName;

        contextInitializer.initializePrebinding({
            fieldsHandler: fieldsHandler,
            context: contextOptions
        });
        contextInitializer.initializeUnderscoreFieldsHelpers(fieldsHandler);
        contextInitializer.initializeUnderscoreLabelsHelpers(labels);
        contextInitializer.initializeUnderscoreContextHelpers(sourceName);
        contextInitializer.initializeContextElement(contextElement);

        this.fields = fieldsHandler;
        this.labels = labels;
        this.element = contextElement;
    }
}
