import { Prebinding } from "../Prebinding";
import { Context } from "../Context";
import { cloneElementSafelyForAccessibility } from "../../utils/HTMLUtils";

export class ElementWithContextInitializer {
    public static initializeContextWithinElement(element: HTMLElement): void {
        if (!!Context.element) {
            element.appendChild(cloneElementSafelyForAccessibility(Context.element));
        }
        Prebinding.applyPrebindingOnElement(element);
        Prebinding.applyPrebindingOnChildren(element);
    }
}
