import {
    IComponentDefinition,
    LazyInitialization
} from "coveo-search-ui";

export function lazyCoveoForSitecoreConfigureSearchHub(): void {
    LazyInitialization.registerLazyComponent("ForSitecoreConfigureSearchHub", () => {
        return new Promise((resolve, reject) => {
            require.ensure(["./CoveoForSitecoreConfigureSearchHub"], () => {
                const loaded = require<IComponentDefinition>("./CoveoForSitecoreConfigureSearchHub.ts")["CoveoForSitecoreConfigureSearchHub"];
                resolve(loaded);
            }, LazyInitialization.buildErrorCallback("CoveoForSitecoreConfigureSearchHub", resolve), "CoveoForSitecoreConfigureSearchHub");
        });
    });
}
