import {
    IComponentDefinition,
    LazyInitialization
} from "coveo-search-ui";

export function lazyValidateAnalyticsIncluded(): void {
    LazyInitialization.registerLazyComponent("ValidateAnalyticsIncluded", () => {
        return new Promise((resolve, reject) => {
            require.ensure(["./ValidateAnalyticsIncluded"], () => {
                const loaded = require<IComponentDefinition>("./ValidateAnalyticsIncluded.ts")["ValidateAnalyticsIncluded"];
                resolve(loaded);
            }, LazyInitialization.buildErrorCallback("ValidateAnalyticsIncluded", resolve), "ValidateAnalyticsIncluded");
        });
    });
}
