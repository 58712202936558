import {
    IComponentDefinition,
    LazyInitialization
} from "coveo-search-ui";

export function lazyCoveoForSitecoreLocalExpression(): void {
    LazyInitialization.registerLazyComponent("ForSitecoreLocalExpression", () => {
        return new Promise((resolve, reject) => {
            require.ensure(["./CoveoForSitecoreLocalExpression"], () => {
                const loaded = require<IComponentDefinition>("./CoveoForSitecoreLocalExpression.ts")["CoveoForSitecoreLocalExpression"];
                resolve(loaded);
            }, LazyInitialization.buildErrorCallback("CoveoForSitecoreLocalExpression", resolve), "CoveoForSitecoreLocalExpression");
        });
    });
}
