import {
    ICoveoFieldHandler,
    IExternalFieldConfig as ILegacyExternalFieldConfig,
    CoveoFieldsHandler
} from "../../handlers/CoveoFieldsHandler";
import {
    IFieldTypeResolver,
    ISourceSpecificFieldConfig,
    IExternalFieldConfig
} from "../../handlers/IFieldTypeResolver";
import { SourceSpecificFieldResolver } from "../../handlers/SourceSpecificFieldResolver";
import { ICoveoLabelsHandler, ILabelsDictionary, CoveoLabelsHandler } from "../../handlers/CoveoLabelsHandler";
import { Prebinding } from "../Prebinding";
import { IPrebindHelperInitializationArgs } from "../../resolvers/prebinding/PrebindHelper";
import { PrebindHelpers } from "../../resolvers/prebinding/PrebindHelpers";

import {
    TemplateHelpers
} from "coveo-search-ui";
import { LegacyFieldTypeResolver } from "../../handlers/LegacyFieldTypeResolver";

export interface ICoveoFieldsHandlerOptions {
    externalFields: ILegacyExternalFieldConfig[] | IExternalFieldConfig[];
    sourceSpecificFields: ISourceSpecificFieldConfig[];
    preferSourceSpecificFields: boolean;
    prefix: string;
    suffix: string;
}

export interface ICoveoLabelsHandlerOptions {
    labels: ILabelsDictionary;
}

export class ContextInitializer {
    public createFieldsHandler(options: ICoveoFieldsHandlerOptions): ICoveoFieldHandler {
        const externalFields: ILegacyExternalFieldConfig[] | IExternalFieldConfig[] = options.externalFields || [];
        const sourceSpecificFields: ISourceSpecificFieldConfig[] = options.sourceSpecificFields || [];

        const fieldTypeResolver: IFieldTypeResolver = options.preferSourceSpecificFields
            ? new LegacyFieldTypeResolver(externalFields as ILegacyExternalFieldConfig[])
            : new SourceSpecificFieldResolver(sourceSpecificFields, externalFields);

        return new CoveoFieldsHandler(fieldTypeResolver, options.prefix, options.suffix);
    }

    public createLabelsHandler(options: ICoveoLabelsHandlerOptions): ICoveoLabelsHandler {
        const labels: ILabelsDictionary = options.labels || {};
        return new CoveoLabelsHandler(labels);
    }

    public initializePrebinding(args: IPrebindHelperInitializationArgs): void {
        PrebindHelpers.getAllPrebindHelpers().forEach(helper => {
            Prebinding.registerPrebindHelper(helper.name, helper.getPrebind(args));
        });
    }

    public initializeUnderscoreFieldsHelpers(fieldsHandler: ICoveoFieldHandler): void {
        TemplateHelpers.registerTemplateHelper("coveoFieldValue", function (fieldName: string): string {
            return this.raw[fieldsHandler.toCoveo(fieldName)];
        });
        TemplateHelpers.registerTemplateHelper("coveoFieldName", function (fieldName: string): string {
            return fieldsHandler.toCoveo(fieldName);
        });
    }

    public initializeUnderscoreLabelsHelpers(labels: ICoveoLabelsHandler): void {
        TemplateHelpers.registerTemplateHelper("translateLabel", function (labelKey: string): string {
            return labels.translate(labelKey);
        });
    }

    public initializeUnderscoreContextHelpers(sourceName: string): void {
        TemplateHelpers.registerTemplateHelper("currentSourceName", function (): string {
            return sourceName;
        });
    }

    public initializeContextElement(contextElement: HTMLElement): void {
        Prebinding.applyPrebindingOnElement(contextElement);
    }
}
