import { initContextOnElement } from "../Initialization";
import { ISearchInterfaceComponentInitializer, getInitializerInstance } from "../../resolvers/components/SearchInterfaceComponentInitializer";
import { ISitecoreExperienceEditor, getExperienceEditorInstance } from "./SitecoreExperienceEditor";

import {
    SearchInterface,
    init
} from "coveo-search-ui";

export class SearchInterfaceInitializer {
    constructor(private experienceEditor: ISitecoreExperienceEditor = getExperienceEditorInstance(), private searchInterfaceComponentInitializer: ISearchInterfaceComponentInitializer = getInitializerInstance()) { }

    public initSearchInterface(searchInterface: HTMLElement): Promise<{ elem: HTMLElement }> {
        if (!this.experienceEditor.isInBackgroundRendering()) {
            this.experienceEditor.registerShowDebugInformationCallback();
            initContextOnElement(searchInterface);
            const searchInterfacePromise = init(searchInterface);
            searchInterfacePromise.done(() => {
                const event = document.createEvent("CustomEvent");
                event.initEvent("SearchInterfaceInitialized", false, true);
                searchInterface.dispatchEvent(event);
            });
            return searchInterfacePromise;
        }
    }

    public initializeExperienceEditorHooksForSearchInterface(searchInterface: HTMLElement): void {
        this.experienceEditor.registerOnLoadRenderingFromUrl(callbackData => {
            const component = this.experienceEditor.getComponentFromLoadRenderingFromUrlCallbackData(callbackData);

            // Since multiple search interfaces can register event handlers, this ensures that the component is initializing for the correct search interface.
            if (searchInterface.contains(component)) {
                const searchInterfaceComponent = Coveo.get(searchInterface, Coveo.SearchInterface) as SearchInterface;
                this.searchInterfaceComponentInitializer.loadComponentsForSearchInterface(searchInterfaceComponent, component);
            }
        });

        this.experienceEditor.registerOnAfterPersonalizationChanged(element => {
            if (searchInterface.contains(element)) {
                const searchInterfaceComponent = Coveo.get(searchInterface, Coveo.SearchInterface) as SearchInterface;
                this.searchInterfaceComponentInitializer.loadComponentsForSearchInterface(searchInterfaceComponent, element);
            }
        });

        this.experienceEditor.registerBeforeUnloadingChrome(element => {
            if (searchInterface.contains(element)) {
                const searchInterfaceComponent = Coveo.get(searchInterface, Coveo.SearchInterface) as SearchInterface;
                this.searchInterfaceComponentInitializer.tryUnloadComponentsForSearchInterface(searchInterfaceComponent, element);
            }
        });
    }
}

