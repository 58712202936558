import { IPrebindHelper, IPrebindHelperInitializationArgs } from "../PrebindHelper";
import { IPrebindHandler } from "../PrebindResolver";

export class AnalyticsEnabledPrebind implements IPrebindHelper {
    public name: string = "analyticsEnabled";

    public getPrebind(args: IPrebindHelperInitializationArgs): IPrebindHandler {
        return (currentValue: string, element: HTMLElement) => {
            return args.context.scAnalyticsEnabled ? "true" : "false";
        };
    }
}
