import {
    IComponentDefinition,
    LazyInitialization
} from "coveo-search-ui";

export function lazyCoveoForSitecoreFilterExpression(): void {
    LazyInitialization.registerLazyComponent("ForSitecoreFilterExpression", () => {
        return new Promise((resolve, reject) => {
            require.ensure(["./CoveoForSitecoreFilterExpression"], () => {
                const loaded = require<IComponentDefinition>("./CoveoForSitecoreFilterExpression.ts")["CoveoForSitecoreFilterExpression"];
                resolve(loaded);
            }, LazyInitialization.buildErrorCallback("CoveoForSitecoreFilterExpression", resolve), "CoveoForSitecoreFilterExpression");
        });
    });
}
