import { IPrebindHelper, IPrebindHelperInitializationArgs } from "../PrebindHelper";
import { IPrebindHandler } from "../PrebindResolver";

export class IsAnonymousPrebind implements IPrebindHelper {
    public name: string = "isAnonymous";

    public getPrebind(args: IPrebindHelperInitializationArgs): IPrebindHandler {
        return (isAnonymous: string, element: HTMLElement) => {
            return args.context.scIsUserAnonymous ? "true" : "false";
        };
    }
}
