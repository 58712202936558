import {
    IComponentDefinition,
    LazyInitialization
} from "coveo-search-ui";

export function lazyCoveoForSitecoreExpressions(): void {
    LazyInitialization.registerLazyComponent("ForSitecoreExpressions", () => {
        return new Promise((resolve, reject) => {
            require.ensure(["./CoveoForSitecoreExpressions"], () => {
                const loaded = require<IComponentDefinition>("./CoveoForSitecoreExpressions.ts")["CoveoForSitecoreExpressions"];
                resolve(loaded);
            }, LazyInitialization.buildErrorCallback("CoveoForSitecoreExpressions", resolve), "CoveoForSitecoreExpressions");
        });
    });
}
